// Styles

/* A */

#alert-style-1 {
  width: 100%;
}

#avatar-dot-pink {
  height: 10px;
  width: 10px;
  background-color: $pink-1;
}

#avatar-dot-pink-diagnose {
  height: 7px;
  width: 7px;
  background-color: $pink-1;
}

#avatar-style-1 {
  height: 89px;
  width: 89px;
  background-color: $gray-1;
}

#avatar-style-2 {
  height: 18px;
  width: 18px;
  background-color: $pink-1;
}

#avatar-style-3 {
  height: 18px;
  width: 18px;
  background-color: $gray-2;
}

#avatar-style-4 {
  height: 18px;
  width: 18px;
  background-color: $orange-1;
}

/* B */

#backgroundcolor-page {
  background-color: white;
}

#backgroundcolor-page-100h-100w {
  background-color: white;
  width: 100%;
  height: 100vh;
}

#box-style-1 {
  width: 100%;
  background-color: $white-2;
  height: 100%;
}

#box-style-2 {
  border-bottom: 1;
  border-color: divider;
}

#box-style-3 {
  width: 100%;
  background-color: white;
  height: 100%;
}

#button-style-1 {
  background-color: $pink-1;
  color: white;
  width: 100%;
  border-radius: 10px;
}

#button-style-2 {
  margin-right: 5px;
  text-transform: none;
}

#button-style-3 {
  text-transform: none;
}

#button-style-4 {
  color: $pink-2;
  background-color: $pink-3;
  border-radius: 10px;
}

#button-style-5 {
  color: $blue-1;
  background-color: $blue-2;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

#button-style-6 {
  color: $red-1;
  background-color: $red-2;
  border-radius: 10px;
}

#button-style-7 {
  text-transform: none;
  color: $white-2;
  height: 35px;
  width: 300px;
}

#back-button-style {
  text-transform: none;
  color: $white-2;
  height: 35px;
  width: 280px;
}
#button-controltab-style {
  text-transform: none;
  color: $white-2;
  height: 35px;
  width: 300px;
}

#button-style-save-reception-form {
  text-transform: none;
  color: $white-2;
  height: 35px;
}

#button-style-image-user-form {
  text-transform: none;
  color: $white-2;
  background-color: #1AA1B9;
  height: 35px;
  width: 200px;
}

#button-none-transform-color-white {
  text-transform: none;
  color: $white-2;
  height: 35px;
}

/* C */

#card-style-error-401 {
  border-radius: 15px;
  margin-top: 5%;
  
}

#card-content-style-1 {
  display: flex;
  padding: 0;
}

#card-style-1 {
  width: 100%;
  border-radius: 10px;
}

#card-style-2 {
  width: 100%;
  margin-bottom: 54px;
}

#card-style-add-rol {
  width: 100%;
  margin-bottom: 54px;
  border-radius: 10px 10px 10px 10px;
  padding-right: 5px;
}
#card-style-show-rol {
  width: 100%;
  margin-bottom: 54px;
  border-radius: 10px 10px 10px 10px;
  padding-left: 5px;
}

#card-style-3 {
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 0;
}

#card-style-4 {
  width: 100%;
  margin-top: 15px;
}

#card-style-5 {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  padding: 0;
}

#card-style-file-result {
  width: 100%;
  margin-bottom: 8px;
}

#checkbox-style-1 {
  color: $pink-1;
}

/* D */

#dialog-content-style-1 {
  padding: 0;
}

#dialog-title-style-1 {
  color: "white";
}

#div-style-add-rol {
  background-color: #EC7BA1;
  padding-top: 0px;
  border-radius: 10px 10px 10px 10px;
}

#div-style-show-rol {
  background-color: #1AA1B9;
  padding-top: 30px;
  border-radius: 10px 10px 10px 10px;
}

#div-scrolling-style-result::-webkit-scrollbar {
  width: 5px; /* Ancho de la barra de desplazamiento vertical */
  background-color: #f1f1f1; /* Color de fondo de la barra de desplazamiento */
}

/* Estilo de la pista del scroll (el área que rodea la barra de desplazamiento) */
#div-scrolling-style-result::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo de la pista del scroll */
}

/* Estilo de la barra de desplazamiento */
#div-scrolling-style-result::-webkit-scrollbar-thumb {
  background-color: #888; /* Color de la barra de desplazamiento */
  border-radius: 5px; /* Radio de borde para hacerlo redondeado */
  width: 5px;
}

/* Cambiar el color del hover de la barra de desplazamiento */
#div-scrolling-style-result::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color de la barra de desplazamiento al pasar el mouse por encima */
}

#div-image-style-1 {
  background-size: contain;
  color: $white-2;
}

#div-style-1 {
  background-color: $white-1;
  width: 100%;
  height: 100vh;
  align-content: center;
  justify-content: center;
}

#div-style-2 {
  width: 45%;
  position: relative;
  margin-left: 25px;
  margin-top: 1rem;
}

#div-style-3 {
  width: 55%;
  text-align: center;
  padding: 54px;
}

#div-style-4 {
  padding-top: 12px;
  margin-bottom: 32px;
}

#div-style-5 {
  margin-top: 13px;
  margin-bottom: 30px;
}

#div-style-6 {
  margin-top: 22px;
  margin-bottom: 15px;
}

#div-style-7 {
  color: $blue-1;
  text-align: right;
  margin-bottom: 51px;
  cursor: pointer;
  font-size: 15px;
}

#div-style-8 {
  display: flex;
}

#div-style-9-result {
  margin-left: 10px;
  margin-top: 50px;
}

#div-style-10-result {
  margin-top: 3vh;
  width:88%;
  height: 38vh;
  margin-left: 6%;
  margin-right: 7%;
}

#div-style-9 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 35px;
  background-color: $blue-1;
}

#div-style-10 {
  margin-left: 3px;
  margin-top: 16px;
  margin-bottom: 10px;
}

#div-style-11 {
  margin-left: 16px;
  margin-right: 2px;
}

#div-style-12 {
  color: $black-6;
  font-size: 18px;
}

#div-style-13 {
  color: $black-1;
  font-size: 35px;
  font-weight: 700;
}

#div-style-14 {
  color: $blue-1;
  font-size: 16px;
  font-weight: 700;
}

#div-style-15 {
  color: $black-6;
  font-size: 16px;
}

#div-style-16 {
  height: 100%;
  width: 100%;
}

#div-style-17 {
  font-size: 24px;
  font-weight: 600;
  margin-left: 5px;
}

#div-style-18 {
  margin-top: 68px;
  margin-bottom: 42px;
}

#div-style-19 {
  display: flex;
  justify-content: space-between;
  margin-left: 120px;
  margin-right: 120px;
}

#div-style-20 {
  margin-top: 34px;
  margin-bottom: 34px;
}

#div-style-21 {
  font-size: 19px;
  margin-left: 14px;
  color: $black-5;
}

#div-style-22 {
  padding-top: 12px;
}

#div-style-23 {
  width: 100%;
  text-align: center;
}

#div-style-24 {
  display: flex;
  justify-content: space-between;
}

#div-style-25 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#div-style-26 {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#div-style-27 {
  font-size: 35px;
  font-weight: 700;
}

#div-style-28 {
  font-size: 20px;
  color: $pink-1;
}

#div-style-29 {
  margin-top: 10px;
  margin-bottom: 23px;
}

#div-style-30 {
  margin-left: 80px;
  margin-right: 80px;
}

#div-style-31 {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
}

#div-style-32 {
  text-align: left;
}

#div-style-33 {
  font-size: 18px;
  font-weight: 700;
}

#div-style-34 {
  color: $black-6;
  font-size: 16px;
}

#div-style-35 {
  color: $black-6;
  font-size: 16px;
  font-weight: 700;
  margin-left: 20px;
}

#div-style-36 {
  font-size: 20px;
  font-weight: 700;
}

#div-style-37 {
  margin-top: 5px;
  margin-bottom: 30px;
}

#div-style-38 {
  padding-left: 56px;
  padding-right: 56px;
}

#div-style-39 {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

#div-style-40 {
  font-size: 18px;
  margin-left: 14px;
}

#div-style-41 {
  font-size: 20px;
}

#div-style-42 {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

#div-style-43 {
  width: 100%;
  position: relative;
}

#div-style-44 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#div-style-45 {
  display: flex;
  align-items: center;
}

#div-style-toggle {
  margin-right: 6px;
  padding-top: 4px;
}

#div-style-percent-negative {
  color: $red-1;
  font-size: 16px;
  font-weight: 700;
}

#div-style-input-error {
  color: $red-3;
  font-size: 10px;
  margin-left: 15px;
  margin-top: 2px;
  text-align: left;
  width: 100%;
}

#drawer-patient-style {
  width: 1000px;
  height: 50px;
}

#divider-style-1 {
  height: 100px;
  background-color: $pink-1;
}

/* F */

#formcontrol-style-1 {
  margin-left: 80px;
}

#formcontrol-style-2 {
  margin-left: 97px;
}

#formlabel-style-1 {
  margin-top: 10px;
  margin-right: 10px;
}

#formcontrollabel-style-1 {
  color: $blue-1;
}

/* G */

#grid-style-1 {
  height: 100vh;
}

#grid-style-2 {
  margin-top: 5px;
}

#grid-style-3 {
  display: flex;
  align-items: center;
  justify-content: end;
}
#grid-style-4 {
  margin-bottom: 10px;
}

#grid-style-5 {
  margin-bottom: 10px;
}

#grid-style-6 {
  margin-bottom: 3px;
  margin-top: 20px;
}

#grid-style-7 {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}

#grid-backbutton-style {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 14px;
  left: 100px;
}

#grid-nextbutton-style {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 14px;
  right: 100px;
}

#grid-style-8 {
  align-content: end;
  display: flex;
  justify-content: end;
}

#grid-style-9 {
  margin-left: 20px;
}

#grid-style-10 {
  margin-top: 10px;
}
#grid-style-11 {
  display: flex;
  align-content: center;
  justify-content: center;
}

#grid-style-12 {
  display: flex;
}

/* H */

#h4-style-permissions {
  margin-top: 5px;
}

#h4-style-users-subtitle {
  margin-top: 0px;
}

/* I */
#icon-style-unlock {
  margin-right: 6px;
}
#icon-style-1 {
  margin-top: 17px;
  margin-right: 6px;
}

#icon-style-user-subtitle {
  margin-right: 6px;
}

#icon-style-2 {
  padding: 10px;
}

#icon-style-3 {
  color: $pink-1;
}

#icon-style-4 {
  color: $blue-1;
  font-size: 14px;
}

#icon-style-5 {
  color: $blue-1;
}

#icon-style-negative {
  color: $red-1;
  font-size: 14px;
}

#image-style-1 {
  float: inline-end;
}

#img-style-2 {
  float: left;
  width: 95%;
  height: 13vh;
  
  
  border-style: dotted;
  border-width: 1px;
  border-color: #B8B8B8;
}

#inputbase-style-1 {
  margin-left: 1;
  border-radius: 0;
  position: "fixed";
  border: 0px solid;
  border-style: none;
  font-size: 16;
  width: 370px;
}
#image-canvas-style {
  margin-top: 15px;
  width: 315px;
}

/* P */

#paper-style-1 {
  border-radius: 10px;
  box-shadow: 0px 3px 6px $black-2;
}

#paper-style-2 {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 10px;
  margin-left: 17px;
  border: 1px solid $pink-2;
  box-shadow: inset 0px 3px 10px $black-3, 3px 4px 3px $black-3;
  border-radius: 8px;
}

#select-filter-reception {
  display: flex;
  align-items: center;
  border: 1px solid $pink-2;
  box-shadow: inset 0px 3px 10px $black-3, 3px 4px 3px $black-3;
  border-radius: 8px;
}

#paper-style-password-input {
  border-radius: 10px;
  box-shadow: 0px 3px 6px $black-2;
  display: flex;
  align-items: center;
}

/* S */

#span-style-1 {
  color: white;
}

#paper-style-3 {
  border-radius: 10px;
  box-shadow: 0px 3px 6px $black-2;
  margin: 7px;
  padding: 14px;
  display: flex;
  justify-content: space-between;
}

/* R */

#react-echart-style-1 {
  height: 45vh;
}

/* S */
#styledtablecell-style-permissions {
  max-width: 300px;
  overflow: clip;
  text-overflow: ellipsis;
}

/* T */

#table-container-style-1 {
  border-radius: 10px;
}

#table-pagination-style-9 {
  display: flex;
  margin-top: 15px;
}

#text-file-style-1-result {
  height: 29vh;
  opacity: 0;
}

#text-style-1 {
  font-size: 21px;
  font-weight: 700;
  color: $pink-1;
}

#text-style-2 {
  font-size: 21px;
  font-weight: 700;
  color: $blue-1;
}

#text-style-3 {
  font-size: 38px;
  font-weight: 700;
}

#text-style-4 {
  font-size: 21px;
  color: $black-1;
}

#tab-style-1 {
  color: $blue-1;
}
